import React from "react"
import {Link} from "gatsby";

import "../css/pages/couverture.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import CouvertureImg from "../images/couverture.jpg"
import Card from "../components/Card/card";
import Contact from "../containers/index/contact";

const Couverture = () => {
    const images = [
        <div>
            <img src={CouvertureImg} className={"img-slider"}/>
        </div>,
        <div>
            <img src={CouvertureImg} className={"img-slider"}/>
        </div>,
        <div>
            <img src={CouvertureImg} className={"img-slider"}/>
        </div>,
    ]

    return (
        <Layout>
            <SEO title="Artisan couvreur à Laon dans l'Aisne (02)"
            description={"L'entreprise Athies Batiment basée à Laon intervient pour les travaux de couverture dans l'Aisne et dans la Marne. Faites appel à nos artisans couvreurs qualifiés."}/>
            <div className={"page couverture"}>
                <div className={"header"}>
                    <h1 className={"title"}>Artisan couvreur à Laon dans l'Aisne (02)</h1>
                </div>

                <Section>
                    <h2>Pourquoi faire appel à nos couvreurs ?</h2>
                    <p>
                        Dans le domaine de la construction ou rénovation d’habitation, la couverture représente <strong>l’agencement des matériaux recouvrant le bâtiment</strong> pour le <strong>protéger des intempéries</strong>. Le but principal de la couverture est de récupérer puis amener les eaux de pluies à un endroit.
                        La couverture doit aussi <strong>résister et protéger votre bâtiment contre la neige, les vents violents et d’autres types d’intempéries</strong>. C’est pour cela qu’il est important d’étudier votre projet au préalable et de choisir les matériaux les plus adaptés pour répondre à ces problématiques.
                    </p>

                    <p>
                        En plus de protéger votre maison contre les aléas de la nature et d’isoler votre maison, la couverture de votre toit est un point essentiel qui définit <strong>l’esthétique de votre habitation</strong> et permet de donner du « cachet » à votre habitation. Athies Batiment vous propose les matériaux les plus intéressants (esthétiques et efficaces) pour votre projet.
                    </p>

                    <p>
                        Le travail de nos couvreurs consiste globalement à :
                        <ul>
                            <li>choisir les matériaux adaptés à votre projet</li>
                            <li>choisir la technique de pose</li>
                            <li>préparer le chantier (comme par exemple tracer l'emplacement des éléments)</li>
                            <li>façonner les matériaux</li>
                            <li>mettre en place les différents matériaux en respectant les règles d'étanchéité, de fixation et de dilatation.</li>
                        </ul>
                    </p>

                    <h2>Rénover votre couverture actuelle</h2>
                    <p>
                        Si vous souhaitez rénover votre couverture actuelle, notre équipe étudiera votre projet et vous proposera la solution la plus adaptée à vos besoins. Ensuite, nos couvreurs réaliseront votre projet afin que vous ayez une solution d'isolation efficace et économique.
                    </p>

                    <h2>Pose d'une couverture neuve</h2>
                    <p>
                        Nos couvreurs assurent aussi la pose de votre couverture neuve, de l'étude de vos travaux à la réalisation du chantier. Nous vous proposerons les solutions les plus économiques et utiles pour votre confort.
                    </p>

                    <p>
                        Nos artisans couvreurs, <strong>qualifiés et expérimentés</strong>, réaliseront vos travaux de couverture selon vos besoins. Nous intervenons principalement dans <strong>l’Aisne (02) et dans la Marne (51)</strong>, dans le secteur proche de Laon, mais nous pouvons aussi nous déplacer dans d'autres secteurs comme Reims, Soissons et Saint-Quentin. Pour cela, contactez-nous : <Link to={"/#contact"}>cliquez-ici</Link>.
                    </p>
                </Section>

                <section>
                    <h2>Nos derniers chantiers</h2>

                    <div className={"row"}>
                        <div className={"card-realisations col-sm-12 col-lg-6 col-xl-3"}>
                            <Card background={CouvertureImg} buttonLink={"/couverture/chantier1"} blur={1}/>
                        </div>
                        <div className={"card-realisations col-sm-12 col-lg-6 col-xl-3"}>
                        </div>
                        <div className={"card-realisations col-sm-12 col-lg-6 col-xl-3"}>
                        </div>
                        <div className={"card-realisations col-sm-12 col-lg-6 col-xl-3"}>
                        </div>
                    </div>
                </section>

                <Contact/>
            </div>
        </Layout>
    )
}

export default Couverture
